<template>
  <div>
    <TheNavbar/>
    <div class="container mt-4">
      <!-- HOURS TITLE -->
      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <router-link :to="{name: 'Home'}" class="btn btn-link btn-micint-xr ripple ripple-primary mx-0">
            <i class="material-icons-outlined">&#xe408;</i>
            Go back
          </router-link>
        </div>
        <div class="col-md-12 animate fadeIn">
          <h1 class="fw-500 typography-headline-lg">Hours</h1>
        </div>
      </div>
      <!-- HOURS LIST -->
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="alert alert-info">
            <span class="fw-500">Remember:</span> These hours will appear on your Menuffy site.
          </div>
        </div>

        <div class="col-md-12">
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <button class="nav-link active" id="nav-regular-tab" data-bs-toggle="tab" data-bs-target="#nav-regular"
                      type="button" role="tab" aria-controls="nav-regular" aria-selected="true">Regular hours
              </button>
              <button class="nav-link" id="nav-special-tab" data-bs-toggle="tab" data-bs-target="#nav-special"
                      type="button" role="tab" aria-controls="nav-special" aria-selected="false">Holiday hours
              </button>
            </div>
          </nav>

          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-regular" role="tabpanel" aria-labelledby="nav-regular-tab">
              <div class="card card-outline mt-3">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-borderless table-hover">
                      <thead>
                      <tr>
                        <th>Day</th>
                        <th>Open time</th>
                        <th>Close time</th>
                        <th>Closed</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="align-middle">Monday</td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td class="align-middle">
                          <div class="theme-switch">
                            <label class="switch">
                              <input type="checkbox" id="checkboxMonday" name="checkbox">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle">Tuesday</td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td class="align-middle">
                          <div class="theme-switch">
                            <label class="switch">
                              <input type="checkbox" id="checkboxTuesday" name="checkbox">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle">Wednesday</td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td class="align-middle">
                          <div class="theme-switch">
                            <label class="switch">
                              <input type="checkbox" id="checkboxWednesday" name="checkbox">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle">Thursday</td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td class="align-middle">
                          <div class="theme-switch">
                            <label class="switch">
                              <input type="checkbox" id="checkboxThursday" name="checkbox">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle">Friday</td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td class="align-middle">
                          <div class="theme-switch">
                            <label class="switch">
                              <input type="checkbox" id="checkboxFriday" name="checkbox">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle">Saturday</td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td class="align-middle">
                          <div class="theme-switch">
                            <label class="switch">
                              <input type="checkbox" id="checkboxSaturday" name="checkbox">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle">Sunday</td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td>
                          <input type="time" class="form-control w-75">
                        </td>
                        <td class="align-middle">
                          <div class="theme-switch">
                            <label class="switch">
                              <input type="checkbox" id="checkboxSunday" name="checkbox">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer text-end">
                  <button class="btn btn-primary ripple px-3">Save hours</button>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="nav-special" role="tabpanel" aria-labelledby="nav-special-tab">
              <div class="card card-outline mt-3">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-borderless table-hover">
                      <thead>
                      <tr>
                        <th>Day</th>
                        <th>Open time</th>
                        <th>Close time</th>
                        <th>Closed</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="align-middle">
                          <button class="btn btn-primary-outline ripple ripple-primary px-3 mx-0">Add new date</button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer text-end">
                  <button class="btn btn-primary ripple px-3">Save hours</button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar";

export default {
  name: "Hours",
  components: {TheNavbar}
}
</script>